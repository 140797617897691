<template>
  <Transition enter-active-class="show-right-full" leave-active-class="show-right-full-out">
    <nav
        class="fixed inset-0 z-900 w-full h-screen overflow-auto bg-bw-2 menu-mobile pointer-events-auto bg-final-01 lg:hidden max-w-screen flex flex-col bg-white"
        v-if="isOpen"
    >
      <div class="container flex-between px-4 py-5 bg-white b-b-1 b-solid b-bw-08 sticky top-0 z-1000">
        <nuxt-link class="md:w-42 w-32 aspect-42/13" :to="$t('ROUTER_HOME')">
          <v-image class="w-full h-full object-contain" :width="250" option_key="logo.global"></v-image>
        </nuxt-link>

        <div class="cursor-pointer flex items-center gap-4">
          <button-primary
              :url="links_global?.download_app"
              class="text-sm"
              padding="py-2.5 px-5 capitalize"
              :title="$t('DOWNLOAD_IZI24')"
              :click-tracking-handler="trackClick"
          ></button-primary>
          <div
              class="flex-none text-bw-15 w-10 h-10 rounded-full flex flex-col items-center justify-center"
              @click="$emit('changeOpenNav', false)"
          >
            <span class="i-custom-close w-7 h-7"></span>
          </div>
        </div>
      </div>
      <MenuItemLeft v-if="isOpen" class="container mt-3"/>
      <div class="border-y-1 mb-[3px] mt-[25px]">
        <NavMenu
            v-for="item in dataRender"
            :title="item?.name"
            :url="item?.url"
            :listNav="item?.childs"
        ></NavMenu>
      </div>
      <MenuItemRight v-if="isOpen" class="container mb-3"/>
    </nav>
  </Transition>
</template>

<script setup lang="ts">
import {useAppGlobalData} from '../../composables'
import NavMenu from './navMenu.vue'
import {
  DOWNLOAD_APP_BY_LINK_KEY, DOWNLOAD_EVENT_ID,
  useEventTracking
} from '../../composables/ackee/event'

const links_global = inject('globalSettings')?.links[0]
defineEmits(['changeOpenNav'])
defineProps({
  isOpen: {
    type: Boolean
  }
})

const lang = useRoute().params.langCode
const {getDataMenuHeader} = useAppGlobalData()
const dataRender = computed(() => {
  if (lang) {
    return getDataMenuHeader(useRoute().params.langCode)
  }
  return
})
const linkItem = (item: any) => {
  if (item.url?.includes('http')) return 'a'
  return resolveComponent('NuxtLink')
}
const currentRoute = useRoute()
const header = ref()

const trackClick = () => {
  useEventTracking(DOWNLOAD_EVENT_ID, DOWNLOAD_APP_BY_LINK_KEY);
};

watch(
    () => currentRoute.path,
    (newValue: string) => {
      if (newValue) {
        setTimeout(() => {
          header.value.classList.remove('activeMenu')
        }, 200)
        setTimeout(() => {
          header.value.classList.add('activeMenu')
        }, 500)
      }
    }
)
</script>

<style>
.sub-menu-mobile li:nth-child(1) span:nth-child(2) {
  @apply text-black hover:text-priamry duration-200;
}
</style>
